import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";

const Empresa = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Administrador de Fincas en Cullera | ArqTéc</title>
        <meta name="description" content="ArqTec - Expertos en administración de fincas e inmobiliaria en Cullera. Ofrecemos servicios integrales para propiedades. ¿Qué es un Administrador de Fincas? Formación, campo de actuación, garantías y funciones del Administrador de Fincas." />
      </Helmet>

      <div className="empresa">
        <h2 className="texto-filosofia">¿Qué es un Administrador de Fincas?</h2>
        <p className="texto-cabecera">
          El <strong>Administrador de Fincas Colegiado</strong> es un profesional liberal que de forma habitual, continuada y retribuida presta servicios de administración y asesoramiento a los propietarios de bienes inmuebles, ya sean estos rústicos o urbanos, administrando sus alquileres y llevando la gestión de sus Comunidades de Propietarios. Desarrollando una actividad de gran valor socioeconómico que afecta, de forma directa, práctica y cotidiana, a la vida de los ciudadanos.
        </p>
        <h2 className="texto-filosofia">Formación</h2>
        <p className="texto-cabecera">
          El <strong>Administrador de Fincas</strong> es un profesional con la formación adecuada para el desempeño de sus funciones. Para acceder al título en <strong>ArqTéc</strong> nos ha sido posible gracias a la Licenciatura en Arquitectura tal y como requiere el Colegio.
        </p>
        <p className="texto-cabecera">
          Al margen de su preparación inicial, el Colegio mantiene la puesta al día de sus miembros, mediante circulares, charlas, cursillos, y a través de su revista URBIS y la del Consejo General ADMINISTRACION RUSTICA Y URBANA. Asimismo, el colegiado tiene el asesoramiento de los servicios del Colegio (Jurídico, Fiscal, Laboral, Arquitectónico…).
        </p>
        <h2 className="texto-filosofia">Su campo de actuación</h2>
        <p className="texto-cabecera">
          El campo de actuación lo comprende todo el sector inmobiliario, bien se trate de inmuebles rústicos o urbanos, en régimen de explotación directa, arrendamiento, propiedad horizontal o cualquier otro; así como la administración de cooperativas de viviendas y comunidades de propietarios para la construcción de sus viviendas, centros comerciales, campos y puertos deportivos, urbanizaciones con servicios, instalaciones y anejos comunes; entidades colaboradoras de la gestión urbanística y, en general, cuantos cometidos guarden relación con la administración de bienes inmuebles.
        </p>
        <h2 className="texto-filosofia">Sus garantías</h2>
        <p className="texto-cabecera">
          El <strong>Administrador de Fincas</strong> tiene obligatoriamente concertados a través del Colegio un seguro de 700.000 euros por siniestro/año y asegurado con una franquicia de 200 euros con un sublímite para errores derivados de la contratación del seguro de 20.000 euros con franquicia de 450 euros por la responsabilidad civil en que pudiera haber incurrido en su actuación profesional; y otro seguro de caución de 30.000 euros para responder de su gestión económica. Además de estas garantías, el Colegio ejerce su actividad disciplinaria cuando la actuación de sus profesionales se aparta de las normas profesionales o deontológicas.
        </p>
        <h2 className="texto-filosofia">Funciones del Administrador de Fincas</h2>
        <div className="contenedor-cajas">
          <div className="caja">
            <ul>
              <li>
                <strong>Gestionar, conservar y rentabilizar los bienes:</strong>
                <br /><br />
                El Administrador de Fincas se encarga de administrar propiedades inmobiliarias de terceros, aplicando criterios profesionales.
              </li>
            </ul>
          </div>
          <div className="caja">
            <ul>
              <li>
                <strong>Mediar:</strong>
                <br /><br />
                Mediar entre propietarios e inquilinos y entre copropietarios de una Comunidad.
              </li>
            </ul>
          </div><div className="caja">
            <ul>
              <li>
                <strong>Conservar y mejorar la propiedad:</strong>
                <br /><br />
                Gestionar el mantenimiento de la propiedad inmobiliaria en previsión de problemas, buscando su mejora constantemente.
              </li>
            </ul>
          </div><div className="caja">
            <ul>
              <li>
                <strong>Aplicar la legislación:</strong>
                <br /><br />
                Interpreta y aplica la legislación vigente, unificando criterios para evitar litigios.
              </li>
            </ul>
          </div>
          <div className="caja">
            <ul>
              <li>
                <strong>Resolver conflictos:</strong>
                <br /><br />
                Busca soluciones a todo tipo de conflictos que se generen en las relaciones arrendaticias y en las Comunidades de Propietarios de manera rápida y eficaz.
              </li>
            </ul>
          </div>
          <div className="caja">
            <ul>
              <li>
                <strong>Representar:</strong>
                <br /><br />
                A la propiedad y a las comunidades de propietarios en todos los casos que profesionalmente les corresponda.
              </li>
            </ul>
          </div>
          <div className="caja">
            <ul>
              <li>
                <strong>Administrar Comunidades de propietarios:</strong>
                <br /><br />
                Asesorándolas en el cumplimiento de la normativa legal, aconsejándole en sus decisiones, velando por su buen funcionamiento y cumpliendo rigurosamente los acuerdos de las juntas.
              </li>
            </ul>
          </div>
          <div className="caja">
            <ul>
              <li>
                <strong>Administrar el arrendamiento:</strong>
                <br /><br />
                Gestiona el alquiler en todos sus aspectos, garantizando la habitabilidad del inmueble para los consumidores y usuarios, buscando al inquilino adecuado, formalizando el contrato correspondiente, depositando la fianza, cobrando y actualizando las rentas, pagando todos los gastos que graven la finca y haciendo un control de los cobros.
              </li>
            </ul>
          </div>
          <div className="caja">
            <ul>
              <li>
                <strong>Valoraciones y tasaciones:</strong>
                <br /><br />
                Los administradores de fincas en su calidad de peritos podrán hacer mediaciones inmobiliarias.. Mediación inmobiliaria.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
};

export default Empresa;
