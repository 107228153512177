const AvisoLegal = () => {
  return (
    <div className="legal">
      <h1>Aviso Legal</h1>
      <p>Condiciones generales de uso del sitio web:</p>

      <h2>1. INTRODUCCIÓN.</h2>
      <p>
        El presente documento tiene como finalidad el establecer y regular las normas de uso del Sitio arqtéc.es (en adelante el "Sitio"), entendiendo por Sitio todas las páginas y sus contenidos propiedad de ARQTÉC a las cuales se accede a través del dominio arqtéc.es y sus subdominios.
      </p>
      <p>
        La utilización del Sitio atribuye la condición de Usuario del mismo e implica la aceptación de todas las condiciones incluidas en el presente Aviso Legal. El Usuario se compromete a leer atentamente el presente Aviso Legal en cada una de las ocasiones en que se proponga utilizar el Sitio, ya que éste y sus condiciones de uso recogidas en el presente Aviso Legal pueden sufrir modificaciones.
      </p>

      <h2>2. TITULARIDAD DEL SITIO WEB.</h2>
      <p>
        El titular del presente Sitio es ARQTÉC con domicilio en C/ Metge Joan Bolufer Borras, 7 Bajo Izquierda / 46400 / Cullera / Valencia / España / NIF: 73765134V. Ud. puede ponerse en contacto con nosotros en el número de teléfono 654 14 57 44 o mediante el email arqtec@arqtec.es.
      </p>

      <h2>3. PROPIEDAD INTELECTUAL E INDUSTRIAL.</h2>
      <p>
        Los derechos de propiedad intelectual del contenido de este Sitio, son titularidad de ARQTÉC.
      </p>
      <p>
        La reproducción, distribución, comercialización o transformación, total o parcial, no autorizadas del contenido del Sitio, a no ser que sea para uso personal y privado, constituye una infracción de los derechos de propiedad intelectual de ARQTÉC Igualmente, todas las marcas o signos distintivos de cualquier clase contenidos en el Sitio están protegidos por Ley.
      </p>
      <p>
        La utilización no autorizada de la información contenida en este Sitio, así como los perjuicios ocasionados en los derechos de propiedad intelectual e industrial de ARQTÉC pueden dar lugar al ejercicio de las acciones que legalmente correspondan y, si procede, a las responsabilidades que de dicho ejercicio se deriven.
      </p>

      <h2>4. EXCLUSIÓN DE RESPONSABILIDAD.</h2>
      <p>
        El contenido, programas, información y/o consejos expresados en este Sitio deben entenderse como simplemente orientativos ARQTÉC no responde de ninguna forma de la efectividad o exactitud de los mismos, quedando exenta de cualquier responsabilidad contractual o extracontractual con los Usuarios que haga uso de ellos, ya que son éstas las que deberán decidir según su criterio la oportunidad de los mismos.
      </p>
      <p>
        En este Sitio se pueden publicar contenidos aportados por terceras personas o empresas, ARQTÉC no responde de la veracidad y exactitud de los mismos, quedando exenta de cualquier responsabilidad contractual o extracontractual con los Usuarios que hagan uso de ellos.
      </p>
      <p>
        ARQTÉC, se reserva el derecho de modificar el contenido del Sitio sin previo aviso y sin ningún tipo de limitación.
      </p>
      <p>
        Asimismo, declina cualquier responsabilidad por los eventuales daños y perjuicios que puedan ocasionarse por la falta de disponibilidad y/o continuidad de este Sitio y de los servicios que se ofrecen en el. ARQTÉC, no garantiza la ausencia de virus ni de otros elementos en la web que puedan producir alteraciones en su sistema informático.
      </p>
      <p>
        ARQTÉC, declina cualquier responsabilidad contractual o extracontractual con los Usuarios que hagan uso de ello y tuviera perjuicios de cualquier naturaleza ocasionados por virus informáticos o por elementos informáticos de cualquier índole.
      </p>
      <p>
        ARQTÉC, declina cualquier responsabilidad por los servicios que eventualmente pudieran prestarse en el Sitio por parte de terceros.
      </p>
      <p>
        ARQTÉC, declina cualquier responsabilidad por los servicios y/o información que se preste en otros Sitios enlazados con este.
      </p>
      <p>
        ARQTÉC, no controla ni ejerce ningún tipo de supervisión en Sitios Webs de terceros. Aconsejamos a los Usuarios de los mismos a actuar con prudencia y consultar las eventuales condiciones legales que se expongan en dichas webs.
      </p>
      <p>
        Los Usuarios que remitan cualquier tipo de información a ARQTÉC, se comprometen a que la misma sea veraz y que no vulnere cualquier derecho de terceros ni la legalidad vigente.
      </p>

      <h2>5. CONDICIONES DE USO:</h2>
      <p>
        El acceso al presente Sitio es gratuito salvo en lo relativo al coste de la conexión a través de la red de telecomunicaciones suministrada por el proveedor de acceso contratado por los usuarios.
      </p>
      <p>
        Queda expresamente prohibido el uso del Sitio con fines lesivos de bienes o intereses de ARQTÉC o de terceros o que de cualquier otra forma sobrecarguen, dañen o inutilicen las redes, servidores y demás equipos informáticos (hardware) o productos y aplicaciones informáticas (software) de ARQTÉC, o de terceros.
      </p>
      <p>
        En el caso de que el Usuario tuviera conocimiento de que los Sitios enlazados remiten a páginas cuyos contenidos o servicios son ilícitos, nocivos, denigrantes, violentos o contrarios a la moral le agradeceríamos que se pusiera en contacto con ARQTÉC
      </p>

      <h2>6. LEGISLACIÓN.</h2>
      <p>
        Las presentes condiciones generales se rigen por la legislación española. El Usuario acepta estos Términos de Uso, y cualquier tipo de controversia relativa al uso de este sitio web, será interpretado con arreglo a las leyes vigentes en el lugar de residencia del propietario de este sitio web, teniendo competencia para la resolución de todo litigio o conflicto que pueda surgir los Juzgados y Tribunales competentes del lugar de residencia del propietario de este sitio web y sus superiores jerárquicos, renunciando expresamente el Usuario a cualquier otro fuero.
      </p>
    </div>
  );
};

export default AvisoLegal;
