import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

class SEO extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "ArqTéc - Administración de Fincas e Inmobiliaria",
            description: "ArqTec, expertos en administración de fincas e inmobiliaria en Cullera. Ofrecemos servicios integrales para propiedades.",
            keywords: "ArqTec, colegiado, administrador, administrador de fincas colegiado, administrador de fincas, fincas, comunidades de vecinos, comunidades, administrador de comunidades, administracion, administracion de comunidades, administración de fincas, inmobiliaria, servicios inmobiliarios, Cullera, Valencia, España, Brosquil, Faro, Bega, San Antonio, Mareny",
            logo: "https://arqtec.es/logo192.jpg",
            favicon: "https://arqtec.es/favicon.ico",
            manifest: "https://arqtec.es/manifest.webmanifest",
            web: "https://www.arqtec.es/",
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <HelmetProvider>
                <Helmet prioritizeSeoTags>
                    <meta http-equiv="content-type" content="text/html; charset=utf-8" />
                    <meta name="mobile-web-app-capable" content="yes" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />

                    <title>{this.state.title}</title>
                    <meta name="description" content={this.state.description} />
                    <meta name="keywords" content={this.state.keywords} />

                    {/* Whatsapp y Facebook */}
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={this.state.title} />
                    <meta property="og:description" content={this.state.description} />
                    <meta property="og:image" content={this.state.logo} />
                    <meta property="og:url" content={this.state.web} />
                    <meta property="og:locale" content="es_ES" />

                    {/* Twitter */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={this.state.title} />
                    <meta name="twitter:description" content={this.state.description} />
                    <meta name="twitter:image" content={this.state.logo} />
                    <meta name="twitter:url" content={this.state.web} />

                    {/* Favicon y Manifest */}
                    <link rel="icon" href={this.state.favicon} />
                    <link rel="manifest" href={this.state.manifest} />
                    <link rel="canonical" href={this.state.web} />

                    {/* Ícono de Apple Touch */}
                    <link rel="apple-touch-icon" href={this.state.logo} />

                    {/* Ícono de Android */}
                    <link rel="icon" sizes="192x192" href={this.state.logo} />
                </Helmet>
            </HelmetProvider>
        );
    }
}

export default SEO;