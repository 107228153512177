const Contacto = () => {
  return (
    <div className="container">
      <div>
        <div className="top-container">
          <h2>Estamos ubicados en</h2>
        </div>
        <div className="bottom-container">
          <div className="phone-container">
            <div className="icon-container">
              <img src="/images/phone.svg" alt="Phone" className="menu_icon" loading="eager"></img>
            </div>
            <div className="text-container">
              <a href="tel:961068628">961 068 628</a>
              <a href="tel:657675093">657 675 093</a>
              <a href="tel:659885999">659 885 999</a>
            </div>
          </div>
          <div className="ubication-container">
            <div className="icon-container">
              <img src="/images/maps.svg" alt="Maps" className="menu_icon" loading="eager"></img>
            </div>
            <div className="text-container">
              <a href="https://www.google.com/maps?q=C+Metge+Joan+Bolufer+Borras,+7+Bajo" target="_blank" rel="noopener noreferrer">C\ Metge Joan Bolufer Borras, 7 Bajo Izquierda. Cullera, Valencia</a>
            </div>
          </div>
          <div className="email-container">
            <div className="icon-container">
              <img src="/images/email.svg" alt="Email" className="menu_icon" loading="eager"></img>
            </div>
            <div className="text-container">
              <a href="mailto:arqtec@arqtec.es">arqtec@arqtec.es</a>
              <a href="mailto:balbinomagraner@arqtec.es">balbinomagraner@arqtec.es</a>
              <a href="mailto:carolfemenia@arqtec.es">carolfemenia@arqtec.es</a>
            </div>
          </div>
        </div>
      </div>
      <div className="maps-container">
        <iframe
          title="Ubicación en Google Maps"
          loading="lazy"
          allowFullScreen
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3093.6234649165203!2d-0.250787!3d39.160545!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd61c89b72c44a71%3A0xeefccdcc38417af7!2sC.%20M%C3%A9dico%20Juan%20Bolufer%20Borr%C3%A1s%2C%207%2C%2046400%20Cullera%2C%20Valencia%2C%20Espa%C3%B1a!5e0!3m2!1ses!2sus!4v1703673998954!5m2!1ses!2sus"
          frameBorder="0"
        ></iframe>
      </div>
    </div>
  );
};

export default Contacto;
