import { useState } from 'react';
import { NavLink } from 'react-router-dom';

const Cabecera = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleNavLinkClick = () => {
    // Cierra el menú al hacer clic en un NavLink dentro del menú
    setIsMenuOpen(false);
  };

  const menuButtonLines = () => {
    return (
      <>
        <img src="/images/menu.svg" alt="Logo Menu" height="25px" width="25px" className="menu" loading="eager"></img>
      </>
    );
  };

  const closeButtonLines = () => {
    return (
      <>
        <img src="/images/x.svg" alt="Logo Cierre Menu" height="25px" width="25px" className="menu" loading="eager"></img>
      </>
    );
  };

  return (
    <div>
      <div className="cabecera">
        <nav>
          <div className="logos">
            <img src="/images/logo.webp" alt="Logo ArqTéc" className="logo"></img>
          </div>
          <NavLink to="/">Inicio</NavLink>
          <NavLink to="/empresa" >Empresa</NavLink>
          <NavLink to="/inmobiliaria" >Inmobiliaria</NavLink>
          <NavLink to="/contacto" >Contacto</NavLink>
          <div className="menu-button" onClick={handleMenuClick}>
            {isMenuOpen ? closeButtonLines() : menuButtonLines()}
          </div>
          <ul className={isMenuOpen ? 'show' : ''}>
            <li><NavLink to="/" onClick={handleNavLinkClick}>Inicio</NavLink></li>
            <li><NavLink to="/empresa" onClick={handleNavLinkClick}>Empresa</NavLink></li>
            <li><NavLink to="/inmobiliaria" onClick={handleNavLinkClick}>Inmobiliaria</NavLink></li>
            <li><NavLink to="/contacto" onClick={handleNavLinkClick}>Contacto</NavLink></li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Cabecera;