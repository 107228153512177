import React, { useEffect, useState } from 'react';

const Copyright = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    // Actualiza el año actual al montar el componente
    setCurrentYear(new Date().getFullYear());
  }, []);

  return (
    <div className="copyright">
      <p>Copyright &copy; {currentYear} ArqTéc. Todos los derechos reservados. Designed by <a href="mailto:contacto.navigate322@passinbox.com">damagr</a>.</p>
    </div>
  );
};

export default Copyright;
