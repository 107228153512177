import React from 'react';

import { HashRouter as Router, Routes, Route } from 'react-router-dom';

import Cabecera from './components/Cabecera';
import Cuerpo from './components/Cuerpo';
import Pie from './components/Pie';
import Copyright from './components/Copyright';
import SEO from './components/SEO';
import PurificadorDOM from './components/PurificadorDOM';
import CookiesConsent from './components/CookiesConsent';

import Inicio from './pages/Inicio';
import Empresa from './pages/Empresa';
import Inmobiliaria from './pages/Inmobiliaria';
import Contacto from './pages/Contacto';
import AvisoLegal from './pages/AvisoLegal';
import PoliticaCookies from './pages/PoliticaCookies';
import PoliticaPrivacidad from './pages/PoliticaPrivacidad';

const App = () => {
  return (
    <Router>
      <div>
        <SEO />
        <div className="app">
          <CookiesConsent />
          <Cabecera />
          <Cuerpo>
            <PurificadorDOM />
            <Routes>
              <Route index path="/" element={<Inicio />} />
              <Route path="/empresa" element={<Empresa />} />
              <Route path="/inmobiliaria" element={<Inmobiliaria />} />
              <Route path="/contacto" element={<Contacto />} />
              <Route path="/avisolegal" element={<AvisoLegal />} />
              <Route path="/politicacookies" element={<PoliticaCookies />} />
              <Route path="/politicaprivacidad" element={<PoliticaPrivacidad />} />
            </Routes>
          </Cuerpo>
          <Pie />
          <Copyright />
        </div>
      </div>
    </Router>
  );
};

export default App;