const Horario = () => {
  return (
    <div className="horario-container horario">
      <img src="/images/colegiado.webp" alt="Administrador fincas colegiado" loading="eager" width="250px" height="250px" className="imagen-responsive colegiado-image"></img>

      <div className="columna-central">
        <div className="seccion seccion-top seccion-titulo" >
          <h3>ADMINISTRACIÓN DE FINCAS E INMOBILIARIA</h3>
        </div>
        <div className="seccion seccion-top">
          <h3>Horario de atención en oficina</h3>
          <p>
            <strong>Mañanas:</strong>
            <br />
            De lunes a viernes de 10:00 h - 13:00 h
          </p>

          <p>
            <strong>Tardes:</strong>
            <br />
            De lunes a jueves de 17:00 h - 19:30 h
            <br />
            Viernes tarde de 17:00 h - 18:30 h
          </p>

          <p className="bold">Fuera de este horario concertar cita</p>
        </div>

        <div className="seccion">
          <div className="centrar">
            <h3>Agosto</h3>
            <strong>Media jornada:</strong>
          </div>
          <p className="margen-bottom margen-top">De lunes a viernes de 10:00 h - 13:00 h</p>
        </div>

        <div className="seccion">
          <h3>Octubre</h3>
          <p className="margen-bottom">Cerramos desde el 5 de octubre hasta el 25 de octubre ambos incluidos</p>
        </div>
      </div>
    </div>
  );
};

export default Horario;
