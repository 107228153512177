const PoliticaPrivacidad = () => {
  return (
    <div className="privacidad">
      <h1>Política de Privacidad</h1>
      <p>En <strong>ARQTÉC</strong> estamos muy concienciados con la protección de los datos personales, razón por la cual presentamos esta Política de Privacidad que establece de manera transparente cómo se tratan y protegen sus datos personales. Por tanto, aseguramos la confidencialidad y privacidad de los datos personales que se nos facilitan a través de nuestra web.</p>
      <p>La información recogida a continuación tiene como objetivo facilitar información adicional y detallada a los interesados sobre la Política de Protección de Datos Personales de <strong>ARQTÉC</strong>, dando cumplimiento a la normativa en esta materia, en especial el Reglamento General de Protección de Datos de la Unión Europea, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (Reglamento UE 2016/679 de 27 de abril de 2016).</p>
      <p>Debemos entender como "dato personal" cualquier información concerniente a una persona física identificada o identificable. Entre otros, se incluyen el nombre, apellidos, dirección postal y electrónica y número de teléfono.</p>
      <h2>¿QUIÉNES SON LOS RESPONSABLES DEL TRATAMIENTO DE SUS DATOS PERSONALES?</h2>
      <p>La identidad y datos de contacto de los corresponsables son los siguientes:</p>
      <ul>
        <li><strong>Nombre Empresa</strong> ARQTÉC</li>
        <li><strong>Dirección</strong> C/ Metge Joan Bolufer Borras, 7 Bajo Izquierda</li>
        <li><strong>Población</strong> Cullera</li>
        <li><strong>Provincia</strong> Valencia</li>
        <li><strong>Código Postal</strong> 46400</li>
        <li><strong>País</strong> España</li>
        <li><strong>Teléfono</strong> 657 675 093</li>
        <li><strong>E-mail</strong> arqtec@arqtec.es</li>
      </ul>
      <h2>¿CÓMO OBTENEMOS SUS DATOS PERSONALES?</h2>
      <p>En <strong>ARQTÉC</strong> recogemos sus datos personales principalmente cuando Ud.:</p>
      <ul>
        <li>Nos los facilita voluntariamente al contactar con nosotros a través de nuestra página web, para solicitar información sobre nuestros productos y servicios, o efectuar alguna reclamación.</li>
        <li>Nos los proporciona al realizar la compra de nuestros productos y/o contratación de servicios comercializados a través de nuestra página web.</li>
      </ul>
      <h2>¿CON QUÉ FINALIDAD TRATAMOS SUS DATOS PERSONALES?</h2>
      <p>Utilizamos sus datos con distintas finalidades, que encontrará detalladas a continuación:</p>
      <ul>
        <li>Contacto y solicitud de información: La finalidad con que serán utilizados los datos facilitados, únicamente gestionar dicha solicitud de información.</li>
        <li>Registro de usuario: La finalidad con que serán utilizados los datos facilitados, tramitar su acceso a los servicios prestados por la empresa y suministrarle los productos solicitados.</li>
        <li>Suscripción a Newsletter: La finalidad con que serán utilizados los datos facilitados es el envío de promociones publicitarias.</li>
      </ul>
      <h2>¿CUÁL ES LA LEGITIMACIÓN PARA EL TRATAMIENTO DE SUS DATOS?</h2>
      <p>La base legal para el tratamiento de sus datos es la respuesta a las consultas planteadas o llevar a cabo el registro de usuarios a través de la página web.</p>
      <h2>¿A QUÉ DESTINATARIOS SE CEDERÁN SUS DATOS?</h2>
      <p>No se cederán datos a terceros salvo obligación legal.</p>
      <h2>¿POR CUÁNTO TIEMPO CONSERVAREMOS SUS DATOS?</h2>
      <p>Sus datos personales serán conservados por <strong>ARQTÉC</strong> durante el tiempo necesario para cumplir la finalidad para la cual se recogieron.</p>
      <p>Si sus datos se utilizan para varias finalidades que nos obliguen a conservarlos durante diferentes periodos de tiempo, aplicaremos el plazo de conservación más largo. En ese caso limitaremos el acceso a sus datos solo para la finalidad para la cual son conservados.</p>
      <h2>¿QUÉ SEGURIDAD TIENE EL TRATAMIENTO DE LA INFORMACIÓN?</h2>
      <p>Desde <strong>ARQTÉC</strong> nos esforzamos para cumplir con nuestra obligación de secreto de los datos de carácter personal, por ello adoptamos todas las medidas necesarias para evitar su alteración, pérdida, tratamiento o acceso no autorizado, habida cuenta en todo momento del estado de la tecnología. En concreto utilizamos tecnología de encriptación SSL (Secure Socket Layer) la cual nos permite que se transmitan en forma segura todos los datos, de tal forma que sólo los involucrados en su manejo pueden interpretarlos. La encriptación evita que individuos ajenos, intercepten la información y puedan conocer su contenido.</p>
      <h2>¿CUÁLES SON SUS DERECHOS CUANDO NOS FACILITA SUS DATOS?</h2>
      <p>Todos los usuarios pueden ejercitar cualquiera de los derechos otorgados por la normativa de protección de datos, como el derecho de acceso, limitación de tratamiento, supresión, portabilidad, etc., mediante escrito dirigido a <strong>ARQTÉC</strong>, o enviando un correo electrónico a <a href="arqtec@arqtec.es">arqtec@arqtec.es</a></p>
      <h2>MODIFICACIONES DE LA POLÍTICA DE PRIVACIDAD:</h2>
      <p>Nuestra Política de Privacidad podrá sufrir actualizaciones, debidas a cambios y necesidades legales, así como debidas a mejoras y cambios incluidos en la forma de ofrecer y prestar nuestros servicios. Por ello, le recomendamos que visite y acceda a nuestra Política de Privacidad periódicamente, para conocer los últimos cambios que hayan podido ser incorporados. En caso de que dichos cambios guarden relación con el consentimiento prestado por el usuario, en tal caso le será enviada una notificación independiente y por separado para recabarlo nuevamente.</p>
      <p>Si durante la lectura le ha surgido alguna duda o cuestión sobre nuestra Política de Privacidad o quiere ejercitar algún derecho o acción relativa a sus datos personales, por favor póngase en contacto con nosotros en <a href="arqtec@arqtec.es">arqtec@arqtec.es</a></p>
    </div>
  );
};

export default PoliticaPrivacidad;
