import React from 'react';
import Horario from '../components/Horario';

const Inicio = () => {
  return (
      <Horario />
  );
};

export default Inicio;
