import CookieConsent from "react-cookie-consent";

const CookiesConsent = () => {

  const generateButtonStyles = (backgroundColor) => ({
    color: "#000000",
    fontSize: "16px",
    padding: "10px 20px",
    borderRadius: "5px",
    textDecoration: "none",
    marginTop: "1em",
    marginBottom: "2em",
    background: backgroundColor,
    marginRight: "10px",
  });

  const generateButtonsDivClasses = "cookie-consent-button-wrapper";

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept cookies"
      declineButtonText="Decline cookies"
      cookieName="ArqTec_Cookie_Consent"
      enableDeclineButton
      flipButtons
      buttonStyle={generateButtonStyles("#4CAF50")}
      declineButtonStyle={generateButtonStyles("#FF6352")}
      buttonWrapperClasses={generateButtonsDivClasses}
    >
      Este sitio web utiliza cookies para mejorar la experiencia del usuario. Las cookies son utilizadas exclusivamente con el propósito de mejorar la usabilidad del sitio y no se almacenan con ningún otro fin. Al hacer clic en "Aceptar" o continuar navegando, aceptas el uso de cookies en este sitio.
    </CookieConsent>
  );
};

export default CookiesConsent;
