import { NavLink } from 'react-router-dom';

const Pie = () => {
  return (
    <div className="pie">
      <div>
        <p>Ubicación:</p>
        <a href="https://www.google.com/maps?q=C+Metge+Joan+Bolufer+Borras,+7+Bajo" target="_blank" rel="noopener noreferrer">C\ Metge Joan Bolufer Borras, 7 Bajo Izquierda. Cullera, Valencia</a>
      </div>
      <div>
        <p>Email:</p>
        <a href="mailto:balbinomagraner@arqtec.es">balbinomagraner@arqtec.es</a>
      </div>
      <div>
        <nav>
          <NavLink to="/avisolegal">Aviso legal</NavLink>
          <NavLink to="/politicacookies" >Politica de cookies</NavLink>
          <NavLink to="/politicaprivacidad" >Politica de privacidad</NavLink>
        </nav>
      </div>
    </div>
  );
};

export default Pie;
