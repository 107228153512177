const Inmobiliaria = () => {
  return (
    <div className="inmobiliaria">
      <div className="cabecera-titulo">
        <h2 className="titulo-texto">Acceda a nuestro portal de Idealista<br />haciendo click en la imagen</h2>
      </div>
      <a href="https://www.idealista.com/pro/arqtec-inmobiliaria/" target="_blank" rel="noopener noreferrer">
        <div className="idealista">

        </div>
      </a>
      <div className="bottom-container container_fix">
        <div className="phone-container">
          <div className="icon-container">
            <img src="/images/phone.svg" alt="Phone" className="menu_icon" loading="eager"></img>
          </div>
          <div className="text-container">
            <a href="tel:644486711"><b>Daniel:</b> 644 486 711</a>
            <a href="tel:659885999"><b>Carol:</b> 659 885 999</a>
          </div>
        </div>
        <div className="email-container">
          <div className="icon-container">
            <img src="/images/email.svg" alt="Email" className="menu_icon" loading="eager"></img>
          </div>
          <div className="text-container">
            <a href="mailto:inmograu@arqtec.es">inmograu@arqtec.es</a>
            <a href="mailto:carolfemenia@arqtec.es">carolfemenia@arqtec.es</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inmobiliaria;